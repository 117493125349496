<template>
  <div class="page-container">
    <div class="banner-container" v-if="localProduct.banner_image_url">
      <BannerSkeletonLoader v-if="isLoadingBanner"/>
      <img :src="localProduct.banner_image_url" alt="Banner" class="banner-image" v-show="!isLoadingBanner"
           @load="imageLoaded"/>
      <header class="header">
        <button class="back-button" v-if="!this.$telegram" @click="goBack">
          <img class="back-icon" src="./../../public/assets/chevron.png">
          Back
        </button>
        <!--        <button class="more-button">...</button>-->
      </header>
    </div>
    <div class="product-details">
      <div v-for="section in localProduct.sections" :key="section.id" class="section">
        <div class="section-header" :class="{'section-rtl': this.$i18n.locale === 'fa'}"
             v-show="section.item_type !== 'PAY_METHOD' || section.payment_methods.length > 2">
          <span class="section-title" :class="{'fa': this.$i18n.locale === 'fa'}">{{ section.title }}. </span>
          <span class="section-description">{{ section.description }}</span>
        </div>
        <SelectableItemList
            v-if="section.item_type === 'PAY_METHOD'"
            v-show="section.payment_methods.length > 2"
            :items="section.payment_methods"
            @select="selectItem"
            :sectionType="section.item_type"
            :selected="selectedPaymentMethod ? selectedPaymentMethod.id : null"
        />
        <SelectableItemList
            v-if="section.item_type === 'PLANS'"
            :items="adjustedPlans"
            @select="selectItem"
            :sectionType="section.item_type"
            :selected="selectedPlan ? selectedPlan.id : null"
            :symbolUrl="selectedPaymentMethod ? selectedPaymentMethod.icon_url : null"
            :hasBoldTitle="true"
        />
        <SelectableItemCollection
            v-if="section.item_type === 'REGIONS'"
            :items="section.regions"
            @select="selectItem"
            :selected="selectedRegion ? selectedRegion.id : null"
        />
        <div v-else-if="section.item_type === 'INPUT_OPTION'" class="input-section">
          <input :id="'input-' + section.id" v-model="inputData[section.param_name]"
                 :placeholder="section.inputs[0].placeholder"
                 :class="{'input-error': section.has_error}"
                 @input="checkForError(section.id)"
                 @keydown.enter="closeKeyboard"
                 @focusout="inputFocusOut(section)"
                 type="text"
                 autocomplete="off"
                 spellcheck="false"
                 enterkeyhint="done"
          />
          <div v-if="section.has_error" style="text-align: start">
            <span style="color: red; font-size: 12px">{{ section.error_text }}</span>
          </div>
        </div>
      </div>

      <div class="section" v-if="!this.$telegram && this.$i18n.locale === 'fa'">
        <div class="section-header section-rtl">
          <span class="section-title" :class="{'fa': this.$i18n.locale === 'fa'}">شماره همراه. </span>
          <span class="section-description">لطفا شماره همراه خود را وارد کنید.</span>
        </div>
        <div class="input-section">
          <input v-model="phoneNumber" placeholder="09*********"
                 @keydown.enter="closeKeyboard"
                 @focusout="validatePhoneNumberInput"
                 @input="checkIfPhoneNumberFixed"
                 :class="{'input-error': phoneNumberInputError}"
                 type="text"
                 autocomplete="off"
                 spellcheck="false"
                 enterkeyhint="done"
          />
          <div v-if="phoneNumberInputError" style="text-align: start">
            <span style="color: red; font-size: 12px">شماره همراه وارد شده اشتباه است.</span>
          </div>
        </div>
      </div>

      <div class="buy-now-container" v-if="!this.$telegram">
        <button class="buy-now" @click="buyNow">{{localProduct.btn_text}}</button>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SelectableItemList from '@/components/SelectableItemList.vue'; // Import the new component
import SelectableItemCollection from "@/components/SelectableItemCollection.vue";
import axios from 'axios';
import BannerSkeletonLoader from "@/components/BannerSkeletonLoader.vue";
import config from "@/config";

export default {
  name: 'ProductDetails',
  props: ['id'],
  components: {
    SelectableItemCollection,
    BannerSkeletonLoader,
    SelectableItemList
  },
  data() {
    return {
      inputData: {},
      selectedPaymentMethod: null,
      selectedPlan: null,
      selectedRegion: null,
      adjustedPlans: [], // To store adjusted plans based on selected payment method
      isLoadingBanner: true,
      purchaseId: null,
      publicPath: process.env.BASE_URL,
      email: '',
      phoneNumber: '',
      phoneNumberInputError: false
    }
  },
  computed: {
    ...mapGetters(['selectedProduct']),
    localProduct() {
      return this.selectedProduct || {};
    }
  },
  watch: {
    selectedPaymentMethod(newMethod) {
      this.updatePlansBasedOnPaymentMethod(newMethod);
      this.updatePlansBasedOnRegion(this.selectedRegion)
    },
    selectedRegion(newRegion) {
      this.updatePlansBasedOnPaymentMethod(this.selectedPaymentMethod);
      this.updatePlansBasedOnRegion(newRegion)
    }
  },
  created() {
    // Handle the case where state is not available
    if (!this.localProduct.id) {
      this.$router.replace('/');
    } else {
      if (this.$telegram) {
        this.$telegram.BackButton.show();
        this.$telegram.BackButton.onClick(this.goBack)

        this.$telegram.MainButton.setText('Buy with Star');
        this.$telegram.MainButton.show();
        this.$telegram.MainButton.onClick(this.telegramBuyNow)
      }

      // Initialize adjusted plans with default values
      this.updatePlansBasedOnPaymentMethod(null);

      const plansSection = this.localProduct.sections.find(section => section.item_type === 'PLANS');
      if (plansSection && plansSection.plans.length > 0) {
        console.log("hey")
        this.selectedPlan = plansSection.plans[0]
      }
      const payMethodsSection = this.localProduct.sections.find(section => section.item_type === 'PAY_METHOD');
      if (payMethodsSection && payMethodsSection.payment_methods.length > 0) {
        if (!this.$telegram) {
          console.log(payMethodsSection.payment_methods)
          this.selectedPaymentMethod = payMethodsSection.payment_methods.filter((pm) => pm.id === 2)[0]
          console.log(this.selectedPaymentMethod)
        } else {
          this.selectedPaymentMethod = payMethodsSection.payment_methods.filter((pm) => pm.id === 1)[0]
        }
      }

      const regionsSection = this.localProduct.sections.find(section => section.item_type === 'REGIONS');
      if (regionsSection && regionsSection.regions.length > 0) {
        this.selectedRegion = regionsSection.regions[0]
      }

      // Initialize inputData with default values for telegram premium
      this.localProduct.sections.forEach(section => {
        if (section.item_type === 'INPUT_OPTION') {
          let inputValue = this.inputDefaultValue(section)
          this.inputData[section.param_name] = inputValue
        }
      });

    }
  },
  mounted() {
    if (this.$telegram) {
      this.$telegram.onEvent('invoiceClosed', this.handlePaymentCompleted)
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    imageLoaded() {
      console.log("image loaded")
      this.isLoadingBanner = false;
    },
    selectItem(item) {
      // Handle item selection logic
      console.log("item selected")
      if (item.sectionType === 'PAY_METHOD') {
        this.selectedPaymentMethod = item;
      } else if (item.sectionType === 'PLANS') {
        this.selectedPlan = item;
      } else if (item.sectionType === 'REGIONS') {
        this.selectedRegion = item
      }
    },
    updatePlansBasedOnPaymentMethod(paymentMethod) {
      const factor = paymentMethod && paymentMethod.title === 'Telegram Star' ? 50 : 1; // Example conversion factor
      const plansSection = this.localProduct.sections.find(section => section.item_type === 'PLANS');
      if (plansSection) {
        this.adjustedPlans = plansSection.plans.map(plan => ({
          ...plan,
          price: (parseFloat(plan.price) * factor).toFixed(2).replace(/\.00$/, ''),
          base_price: plan.price
        }));
      }
    },
    updatePlansBasedOnRegion(region) {
      if (!region) {
        return
      }
      this.adjustedPlans = this.adjustedPlans.filter((plan) => plan.region === region.id)
    },
    validateInputs() {
      let isValid = true;
      this.localProduct.sections.forEach(section => {
        if (section.item_type === 'INPUT_OPTION') {
          if (!this.inputData[section.param_name] || this.inputData[section.param_name].trim() === '') {
            section.has_error = true;
            isValid = false;
          } else {
            section.has_error = false;
          }
        }
      });
      return isValid;
    },
    validatePhoneNumberInput() {
      const phoneNumberPattern = /^09\d{9}$/;
      const isValid = phoneNumberPattern.test(this.phoneNumber)
      this.phoneNumberInputError = !isValid
      return isValid
    },
    checkIfPhoneNumberFixed() {
      const phoneNumberPattern = /^09\d{9}$/;
      const isValid = phoneNumberPattern.test(this.phoneNumber)
      if (isValid) {
        this.phoneNumberInputError = false
      }
    },
    checkForError(sectionId) {
      const section = this.localProduct.sections.find(sec => sec.id === sectionId);
      section.has_error = !this.inputData[section.param_name] || this.inputData[section.param_name].trim() === '';
      if (section.has_error) {
        section.error_text = "Please fill this input"
      }
    },
    inputFocusOut(section) {
      const tgUsername = this.inputData[section.param_name]
      const data = {
        "query": tgUsername,
        "method": "searchPremiumGiftRecipient",
        "months": this.selectedPlan.identifier
      }
      const config = {
        headers: {
          'api-key': 'fe81a65a-d920-4f10-9816-c41212e7a3bc'
        }
      }
      const url = "https://tp.creativebug.app/v1/premium/search"
      axios.post(url, data, config).then(response => {
        if (response.status === 201) {
          if (response.data.ok === false) {
            section.error_text = this.$t('telegram_username_input_error')
            section.has_error = true
          }
        }
      })
    },
    closeKeyboard(event) {
      event.target.blur();
    },
    inputDefaultValue(section) {
      if (!this.$telegram) {
        return ""
      }
      const user = this.$telegram.initDataUnsafe.user
      if (!user) {
        return "";
      }
      if (section.title === "Telegram Username") {
        return user.username
      }

      return ""
    },
    async buyNow() {
      if (!this.validateInputs() || !this.validatePhoneNumberInput()) {
        return;
      }

      const newWindow = window.open('', '_blank');
      newWindow.document.write('<html><head><title>Redirecting...</title></head><body><p>Redirecting to ZarinPal...</p></body></html>');
      newWindow.document.close();
      const purchase_json = JSON.stringify(this.inputData)
      newWindow.location.href = `${config.API_BASE_URL}/purchases/zarinpal-order?plan_id=${this.selectedPlan.id}&phone_number=${this.phoneNumber}&purchase_json=${purchase_json}`;
    },
    async telegramBuyNow() {
      if (!this.validateInputs()) {
        return;
      }
      this.$telegram.MainButton.showProgress();
      try {
        const telegram = this.$telegram;
        const user = telegram.initDataUnsafe.user;

        // Send purchase request to the server
        const response = await axios.post(`${config.API_BASE_URL}/purchases/order-plan`, {
          chat_id: user.id, // Use chat_id for Telegram APIs
          plan_id: this.selectedPlan.id,
          purchase_json: this.inputData
        });
        this.$telegram.MainButton.hideProgress();
        // Handle server response
        if (response.data.invoice_link) {
          // window.open(response.data.invoice_link, "_blank")
          telegram.openInvoice(response.data.invoice_link)
          this.purchaseId = response.data.purchase_id
        }
      } catch (error) {
        console.error('Purchase error:', error);
        this.$telegram.MainButton.hideProgress();
        alert('An error occurred while processing your purchase.');
      }
    },
    cancelOrder() {
      axios.post(`${config.API_BASE_URL}/purchases/cancel-order`, {
        purchase_id: this.purchaseId
      })
    },
    handlePaymentCompleted(params) {
      const {status} = params;

      if (status === 'paid') {
        this.$telegram.close()
      } else if (status === 'failed') {
        alert('An error occurred while processing your purchase.');
      } else if (status === 'cancelled') {
        this.cancelOrder()
      }
    }
  }
}
</script>

<style scoped>
.page-container {
  padding-bottom: 74px;
}

.banner-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
}

.banner-image {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: 393/200;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: none; /* Remove background color */
}

.back-button, .more-button {
  font-family: Avenir,Helvetica,Arial,sans-serif;
  display: flex;
  align-items: center;  /* Align items vertically */
  background: none;
  border: none;
  color: #007aff;
  font-size: 16px;
  padding: 0;  /* Remove any default padding */
}

.back-icon {
  margin-right: 8px;  /* Add some space between the icon and the text */
}

.product-details {
  background-color: #1C1C1C;
  color: white;
  padding: 0 16px 16px 16px;
  //font-family: YekanBakh;
  //font-family: Arial, sans-serif;
}

.section {
  margin-bottom: 24px;
}

.section-header {
  display: block;
  margin-bottom: 8px;
  line-height: 1.5;
  text-align: start;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  text-transform: capitalize;
}

.section-title.fa {
  font-family: YekanBakh-Heavy;
  font-weight: normal;
}

.section-rtl {
  direction: rtl;
}

.section-description {
  font-size: 16px;
  color: #cccccc;
  display: inline;
}

.input-section {
  display: flex;
  flex-direction: column;
}

.input-section input {
  height: 28px; /* Set the height of the input */
  padding: 8px;
  border-radius: 12px; /* Set the border radius of the input */
  border: 1px solid #2C2C2D;
  background: #2C2C2D; /* Update background color */
  color: white;
}

.input-section input::placeholder {
  color: white;
  opacity: 0.2;
}

.input-error {
  border-color: red !important;
}

.buy-now {
  background: #007aff;
  color: white;
  padding: 16px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  max-width: calc(460px - 32px);
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.buy-now-container {
  position: fixed;
  border-top: 1px solid #383838;
  bottom: 0;
  left: 0;
  right: 0;
  height: 82px;
  background-color: #1C1C1C;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

@media (min-width: 460px) {
  .buy-now {
    margin: 0 16px; /* Ensure the button has 16px margin on both sides */
  }
}
</style>
