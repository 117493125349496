<template>
  <div class="item-selector">
    <div
        v-for="item in items"
        :key="item.title"
        :class="['item', { selected: item.id === selectedItemId }]"
        @click="selectItem(item)"
    >
      <img :src="item.icon_url" :alt="item.title" />
      <div class="item-title">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectableItemCollection',
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: Number,
      default: null
    },
    sectionType: {
      type: String,
      default: 'REGIONS'
    }
  },
  data() {
    return {
      selectedItemId: this.selected
    }
  },
  watch: {
    selected(newVal) {
      this.selectedItemId = newVal;
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItemId = item.id;
      item.sectionType = this.sectionType; // Add section type to item
      this.$emit('select', item);
    },
  }
}
</script>

<style scoped>
.item-selector {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border: 1px solid #464648;
  border-radius: 12px;
  background-color: #2C2C2D;
  cursor: pointer;
  text-align: center;
}

.item.selected {
  border: 2px solid #007aff;
  padding: 15px;
}

.item img {
  width: 32px;
  height: 25px;
  object-fit: cover;
}

.item-title {
  margin-top: 8px;
  font-size: 12px;
  font-weight: normal;
}
</style>
