<template>
  <div class="selectable-item-list" :class="{'rtl': this.$i18n.locale === 'fa'}">
    <div
        v-for="item in items"
        :key="item.id"
        :class="['selectable-item', { selected: item.id === selectedItemId }]"
        @click="selectItem(item)">
      <div :class="this.$i18n.locale !== 'fa' ? 'item-left' : 'item-right'">
        <img v-if="item.icon_url" :src="item.icon_url" :alt="item.title" />
        <p :class="{'bold-title': hasBoldTitle}">{{ item.title }}</p>
      </div>
      <div :class="this.$i18n.locale !== 'fa' ? 'item-right' : 'item-left'" v-if="item.price">
        <span v-if="item.base_price !== item.price && this.$telegram">{{formatPrice(item.base_price - 0.01)}} $</span>
        <img v-if="symbolUrl && this.$telegram" :src="symbolUrl" />
        <p v-if="this.$telegram">{{ formatPrice(item.price) }}</p>
        <p v-if="!this.$telegram">{{ formatPrice(item.rial_price) }} تومان</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectableItemList',
  props: {
    items: {
      type: Array,
      required: true
    },
    sectionType: {
      type: String,
      required: true
    },
    selected: {
      type: Number,
      default: null
    },
    symbolUrl: {
      type: String,
      default: null
    },
    hasBoldTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItemId: this.selected
    }
  },
  watch: {
    selected(newVal) {
      this.selectedItemId = newVal;
    }
  },
  methods: {
    selectItem(item) {
      this.selectedItemId = item.id;
      item.sectionType = this.sectionType; // Add section type to item
      this.$emit('select', item);
    },
    formatPrice(price) {
      // Remove decimal part if it's zero
      let newPrice = price
      newPrice = parseFloat(price).toFixed(2).replace(/\.00$/, '');
      newPrice = parseFloat(newPrice);

      if (this.$telegram) {
        return newPrice
      }

      newPrice = newPrice / 10
      return newPrice.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      // return parseFloat(price).toFixed(2).replace(/\.00$/, '');
    }
  }
}
</script>

<style scoped>
.selectable-item-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.selectable-item-list.rtl {
  direction: rtl;
}

.selectable-item {
  background: #2C2C2D;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 2px solid #464648;
  height: 22px; /* Set a fixed height for all items */
  justify-content: space-between; /* Space between left and right content */
}

.selectable-item.selected {
  border: 2px solid #007aff;
}

.item-left {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.item-left img {
  width: 36px;
  height: 36px;
  margin-right: 4px;
}

.item-right img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.item-left p, .item-right p {
  margin: 0;
  font-size: 14px;
}

p.bold-title {
  font-weight: bold;
}

.item-right {
  margin-left: auto;
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.item-right span {
  margin-right: 8px;
  font-size: 14px;
  color: white;
  opacity: 0.18;
}
</style>
