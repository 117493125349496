// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyAlXNPJTOX-t6Tfpb7mU3n_jS2ydcXcU94",
    authDomain: "wondergift-d7199.firebaseapp.com",
    projectId: "wondergift-d7199",
    storageBucket: "wondergift-d7199.appspot.com",
    messagingSenderId: "1080505860004",
    appId: "1:1080505860004:web:8a818eb14addfe5ee04b31",
    measurementId: "G-CQTX8GJ32M"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export { analytics }