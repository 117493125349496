import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import '@/assets/fontiran.css'
import {VueFire} from "vuefire";
import {firebaseApp, analytics} from "@/firebase";
import { logEvent } from 'firebase/analytics';
import i18n from "@/i18n";

const app = createApp(App)

router.afterEach((to) => {
    logEvent(analytics, 'page_view', { page_path: to.path });
});

app.config.globalProperties.$telegram = window.Telegram.WebApp.initDataUnsafe.user !== undefined ?
    window.Telegram.WebApp : null;

if (app.config.globalProperties.$telegram !== null) {
    i18n.global.locale = 'en';
}

app.use(router)
app.use(store);
app.use(i18n)
app.use(VueFire, {
    firebaseApp
})
app.mount('#app')

window.Telegram.WebApp.ready();