import { createStore } from 'vuex';
import axios from "axios";
import i18n from "@/i18n";

const store = createStore({
    state: {
        products: [],
        selectedProduct: null
    },
    getters: {
        products: state => state.products,
        selectedProduct: state => state.selectedProduct
    },
    mutations: {
        setProducts(state, products) {
            state.products = products;
        },
        setSelectedProduct(state, product) {
            state.selectedProduct = product;
        }
    },
    actions: {
        fetchProducts({ commit }, isTelegram) {
            // Replace with your API call
            let lang = i18n.global.locale
            let type = 'TELEGRAM'
            if (!isTelegram) {
                type = 'WEB'
            }
            axios.get(`https://gbot.creativebug.app/products/get-all?lang=${lang}&type=${type}`)
                .then(response => {
                    commit('setProducts', response.data);
                })
                .catch(error => {
                    console.error("There was an error fetching the products!", error);
                });
        },
        selectProduct({ commit }, product) {
            commit('setSelectedProduct', product);
        }
    },
    modules: {
        // Add modules here if necessary
    }
});

export default store;
