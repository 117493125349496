// src/i18n.js
import {createI18n} from 'vue-i18n';

// Import your translation files
import en from './locales/en.json';
import fa from './locales/fa.json';

// Create the i18n instance
const i18n = createI18n({
    locale: 'fa', // set default locale
    fallbackLocale: 'en', // set fallback locale
    messages: {
        en,
        fa,
    },
});

export default i18n;
