<template>
  <div class="home-view">
    <h2 class="title">{{$t('home_title')}}</h2>
    <nav class="category-nav">
      <button :class="{ active: activeCategory === 'all' }" @click="setCategory('all')">All</button>
      <button :class="{ active: activeCategory === 'giftcards' }" @click="setCategory('giftcards')">Giftcards</button>
      <button :class="{ active: activeCategory === 'accounts' }" @click="setCategory('accounts')">Accounts</button>
    </nav>
    <div class="product-grid">
      <div v-for="product in filteredProducts" :key="product.id" class="product-card" @click="selectProductAction(product)">
        <img :src="product.icon_url" :alt="product.title" />
        <img class="title-img" :src="product.title_image_url">
      </div>
      <SkeletonLoader v-if="products.length === 0"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from "axios";
import SkeletonLoader from "@/components/ProductsSkeletonLoader.vue";
import { logEvent } from 'firebase/analytics';
import {analytics} from "@/firebase";

export default {
  name: 'HomeView',
  components: {SkeletonLoader},
  data() {
    return {
      activeCategory: 'all',
    }
  },
  mounted() {
    if (!this.$telegram) {
      return;
    }
    const user = this.$telegram.initDataUnsafe.user
    if (!user) {
      return;
    }
    axios.post('https://gbot.creativebug.app/users/new', {
      chat_id: user.id, // Use chat_id for Telegram APIs
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name
    });
  },
  computed: {
    ...mapGetters(['products']),
    filteredProducts() {
      if (this.activeCategory === 'all') {
        return this.products;
      }
      return this.products.filter(product => product.category.title.toLowerCase() === this.activeCategory);
    }
  },
  created() {
    if (this.$telegram) {
      this.$telegram.BackButton.hide();
      this.$telegram.MainButton.hide();
    }
    this.fetchProducts(this.$telegram);
  },
  methods: {
    ...mapActions(['fetchProducts', 'selectProduct']),
    setCategory(category) {
      this.activeCategory = category;
    },
    selectProductAction(product) {
      if (product.title === 'Storyfont' && !this.$telegram) {
        logEvent(analytics, "storyfont_tapped")
        window.open('https://storyfont.app/pro/?so=wondergift&utm_source=wondergift', 'blank')
        return
      }
      this.selectProduct(product);
      this.$router.push({name: 'ProductDetails', params: {id: product.id}});
    },
  }
}
</script>

<style scoped>
.home-view {
  background-color: #1C1C1C;
  color: white;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
}

h1 {
  font-size: 16px;
}

.title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
}

.category-nav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  background-color: #2C2C2D;
  border-radius: 12px;
  padding: 6px;
}

.category-nav button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: normal;
  padding: 8px 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  flex: 1;
}

.category-nav button.active {
  background-color: #1C1C1C;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.product-card {
  background: #2C2C2D;
  border-radius: 16px;
  padding: 16px;
  text-align: center;
  cursor: pointer;
}

.product-card img {
  //width: 100%;
  height: 120px;
  //height: auto;
  border-radius: 8px;
  margin-bottom: 8px;
}

.product-card .title-img {
  margin: 0;
  height: 20px;
  max-width: 132px;
  object-fit: contain;
}
</style>
