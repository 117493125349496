<template>
  <div id="app" :class="{'farsi-font': this.$i18n.locale === 'fa'}">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
html, body {
  background-color: #1C1C1C;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  max-width: 460px;
  margin: 0 auto;
}

.farsi-font {
  font-family: 'YekanBakh' !important;
}

button {
  font-family: inherit
}

input {
  font-size: 16px;
}
</style>
