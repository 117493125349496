<template>
  <div class="skeleton-card" v-for="n in 8" :key="n">
    <div class="skeleton-image"></div>
    <div class="skeleton-title-image"></div>
  </div>
</template>

<style scoped>

.skeleton-card {
  //background: #2C2C2D;
  border-radius: 16px;
  padding: 16px;
  text-align: center;
  animation: pulse-bg 1s infinite;
  //width: 174px;
  //height: 180px;
}

.skeleton-image {
  height: 120px;
  width: 142px;
  border-radius: 8px;
  margin-bottom: 8px;
  //animation: pulse-bg 1s infinite;
}

.skeleton-title-image {
  height: 20px;
  //animation: pulse-bg 1s infinite;
  border-radius: 4px;
  width: 132px;
}

@keyframes pulse-bg {
  0% {
    background-color: #333;
  }
  50% {
    background-color: #303030;
  }
  100% {
    background-color: #333;
  }
}
</style>
