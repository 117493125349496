import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import ProductDetails from '@/views/ProductDetails.vue';
import PurchaseConfirmation from "@/views/PurchaseConfirmation.vue";

const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: HomeView
    },
    {
        path: '/product/:id',
        name: 'ProductDetails',
        component: ProductDetails,
        props: true
    },
    {
        path: '/gift_purchase_confirmation',
        name: 'GiftPurchaseConfirmation',
        component: PurchaseConfirmation,
        props: route => ({
            orderId: route.query.order_id,
            typeWithCode: true
        })
    },
    {
        path: '/purchase_confirmation',
        name: 'PurchaseConfirmation',
        component: PurchaseConfirmation,
        props: route => ({
            orderId: route.query.order_id,
            typeWithCode: false
        })
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
