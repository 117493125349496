<template>
  <div class="skeleton-banner-image"></div>
</template>

<style scoped>
.skeleton-banner-image {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: 393/200;
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #333;
  }
  50% {
    background-color: #303030;
  }
  100% {
    background-color: #333;
  }
}
</style>