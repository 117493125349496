<template>
  <div class="container">
    <img src="/assets/success-icon.png" alt="Success Icon" class="icon"/>
    <h1>ممنون از خرید شما</h1>
    <div class="order-number">شماره‌ی سفارش شما: {{ orderId }}#</div>
    <div class="info" v-if="!isLoading && !typeWithCode">
      سفارش شما با موفقیت انجام شد
    </div>
    <div v-if="typeWithCode" class="info">
       کد گیفت کارت را می‌توانید از قسمت زیر کپی کنید.
    </div>
    <div v-if="!isLoading">
      <div v-if="typeWithCode" class="code">
        <span>{{ code }}</span>
        <button @click="copyCode">
          <img src="/assets/copy-icon.png" alt="Copy Icon" class="copy-icon"/>
        </button>
      </div>
      <!--      <div v-else class="activated-info">-->
      <!--        سفارش شما با موفقیت انجام شد.-->
      <!--      </div>-->
    </div>
    <div v-else class="loader-container">
      <div class="loader"></div>
    </div>
    <a href="https://t.me/creativeSupport" class="help" target="_blank">ارتباط با پشتیبانی</a>
  </div>
</template>

<script>
import config from "@/config";
import axios from "axios";

export default {
  props: {
    orderId: {
      type: String,
      required: true
    },
    typeWithCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      code: null,
      isLoading: false
    };
  },
  methods: {
    fetchOrderDetails(orderId) {
      this.isLoading = true
      axios.post(`${config.API_BASE_URL}/giftcards/get-gift-card`, {
        purchase_id: orderId
      })
          .then(response => {
            const data = response.data;
            if (data.code) {
              this.code = data.code;
            }
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error fetching order details:', error);
            this.isLoading = false;
          });
    },
    copyCode() {
      navigator.clipboard.writeText(this.code).then(() => {
        alert('Code copied to clipboard');
      });
    }
  },
  mounted() {
    if (this.typeWithCode) {
      this.fetchOrderDetails(this.orderId);
    }
  }
};
</script>

<style scoped>
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  color: white;
}

.container {
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  color: white;
  margin: auto;
}

.icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.order-number {
  margin: 20px 0;
  font-size: 18px;
  direction: rtl
}

.info {
  margin: 20px 0;
  font-size: 14px;
  direction: rtl;
}

.code, .activated-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  background-color: #333;
  padding: 10px;
  border-radius: 12px;
  font-size: 15px;
  border: 1px solid #464648;
  position: relative;
  min-height: 40px;
}

.activated-info {
  color: white;
  font-family: Arial, sans-serif;
}

.code span {
  flex-grow: 1;
  text-align: center;
  line-height: 1; /* Ensure text is vertically centered */
  font-family: Arial, sans-serif; /* Apply Arial font only to code span */
}

.code button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copy-icon {
  width: 24px;
  height: 24px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.help {
  color: #3498db;
  text-decoration: none;
}
</style>
